import React, { useEffect } from 'react';
import FadeIn from "react-lazyload-fadein";

import image_omm from '../assets/omm.png';
import image_support from '../assets/support.png';
import image_viscenter from '../assets/viscenter.png';
import image_clinic from '../assets/clinic.png';
import image_cancer from '../assets/cancer.png';
import image_clinic_jb from '../assets/clinic-jb.png';
import image_help_is_here from '../assets/help-is-here.png';
import attendance from '../assets/attendance.png';
import image_smf from '../assets/smf.png';
import image_rca from '../assets/rca.png';
import image_dost from '../assets/dost.jpg';
import image_mike from '../assets/mike.jpg';
import image_mihir from '../assets/mihir.jpg';
import image_damon from '../assets/damon.png';
import image_steve from '../assets/steve.jpg';
import image_lou from '../assets/lou.jpg';
import image_michelle from '../assets/michelle.jpg';
import image_nick from '../assets/nick.png';
import image_mario from '../assets/mario.png';
import image_annemarie from '../assets/annemarie.png';

const Home = () => {
  return (
    <section id="page__home">
      <div className="max-w-6xl mx-auto" style={{ minWidth: "325px" }}>
        <div className="home py-6 px-3 sm:mx-12">
          <div className="home__heading">
            <h1 className="font-extrabold text-2xl sm:text-4xl w-56 sm:w-full max-w-2xl leading-none mb-0 sm:mb-2 sm:leading-9">Academic Technologies Group</h1>
            <p className="text-gray-700 text-xs sm:text-lg">Web Applications</p>
            {/* <div className="flex flex-col section-contacts">
              <div>
                <p className="text-xs sm:text-base text-gray-700">Team Members</p>
                <FadeIn>
                  {onload => (
                    <div className="section-contacts__images flex" onLoad={onload}>
                      <img src={image_dost} alt="team member Dost" className="h-10 w-10 sm:w-12 sm:h-12" />
                      <img src={image_mike} alt="team member Mike" className="h-10 w-10 sm:w-12 sm:h-12" />
                      <img src={image_steve} alt="team member Steve" className="h-10 w-10 sm:w-12 sm:h-12" />
                      <img src={image_mihir} alt="team member Mihir" className="h-10 w-10 sm:w-12 sm:h-12" />
                      <img src={image_damon} alt="team member Damon" className="h-10 w-10 sm:w-12 sm:h-12" />
                      <img src={image_lou} alt="team member Lou" className="h-10 w-10 sm:w-12 sm:h-12" />
                      <img src={image_michelle} alt="team member Michelle" className="h-10 w-10 sm:w-12 sm:h-12" />
                      <img src={image_nick} alt="team member Nick" className="h-10 w-10 sm:w-12 sm:h-12" />
                      <img src={image_mario} alt="team member Mario" className="h-10 w-10 sm:w-12 sm:h-12" />
                      <img src={image_annemarie} alt="team member AnneMarie" className="h-10 w-10 sm:w-12 sm:h-12" />
                    </div>
                  )}
                </FadeIn>
              </div>
            </div> */}
          </div>
          <div className="flex justify-center">
            <div className="flex flex-wrap py-8 sm:py-16 -mx-2">
              <a href="https://comapp.nyit.edu/support" className="w-1/3 md:w-1/4 lg:w-1/5 px-2 my-0 sm:my-1 sm:px-4">
                <FadeIn>
                  {onload => (
                    <div>
                      <img
                        src={image_support}
                        onLoad={onload}
                        alt="Logo for The Academic Technologies Group Support Website"
                        className="drop-shadow"
                      />
                      <p className="text-xs sm:text-base leading-tight sm:leading-5 px-2 pt-1 pb-3 md:pt-3 text-gray-700 text-center">Student Walk-in Support</p>
                    </div>
                  )}
                </FadeIn>
              </a>
              <a href="https://comresearchapp.nyit.edu/clinic" className="w-1/3 md:w-1/4 lg:w-1/5 px-2 my-0 sm:my-1 sm:px-4">
                <FadeIn>
                  {onload => (
                    <div>
                      <img
                        src={image_clinic}
                        onLoad={onload}
                        alt="Logo for The NYIT Health Care Centers" className="drop-shadow"
                      />
                      <p className="text-xs sm:text-base leading-tight sm:leading-5 px-2 pt-1 pb-3 md:pt-3 text-gray-700 text-center">Academic Health Centers</p>
                    </div>
                  )}
                </FadeIn>
              </a>
              <a href="https://comapp.nyit.edu/viscenter" className="w-1/3 md:w-1/4 lg:w-1/5 px-2 my-0 sm:my-1 sm:px-4">
                <FadeIn>
                  {onload => (
                    <div>
                      <img
                        src={image_viscenter}
                        onLoad={onload}
                        alt="Logo for The Visualization Center Website"
                        className="drop-shadow"
                      />
                      <p className="text-xs sm:text-base leading-tight sm:leading-5 px-2 pt-1 pb-3 md:pt-3 text-gray-700 text-center">Visualization Center</p>
                    </div>
                  )}
                </FadeIn>
              </a>
              <a href="https://comapp.nyit.edu/omm-center" className="w-1/3 md:w-1/4 lg:w-1/5 px-2 my-0 sm:my-1 sm:px-4">
                <FadeIn>
                  {onload => (
                    <div>
                      <img
                        src={image_omm}
                        onLoad={onload}
                        alt="Logo for The Omm Center Website"
                        className="drop-shadow"
                      />
                      <p className="text-xs sm:text-base leading-tight sm:leading-5 px-2 pt-1 pb-3 md:pt-3 text-gray-700 text-center">OMM Center</p>
                    </div>
                  )}
                </FadeIn>
              </a>
              <a href="https://comapp.nyit.edu/cancer-research" className="w-1/3 md:w-1/4 lg:w-1/5 px-2 my-0 sm:my-1 sm:px-4">
                <FadeIn>
                  {onload => (
                    <div>
                      <img
                        src={image_cancer}
                        onLoad={onload}
                        alt="Logo for The Center for Cancer Research"
                        className="drop-shadow"
                      />
                      <p className="text-xs sm:text-base leading-tight sm:leading-5 px-2 pt-1 pb-3 md:pt-3 text-gray-700 text-center">Cancer Research</p>
                    </div>
                  )}
                </FadeIn>
              </a>
              <a href="https://comresearchapp.nyit.edu/smfb" className="w-1/3 md:w-1/4 lg:w-1/5 px-2 my-0 sm:my-1 sm:px-4">
                <FadeIn>
                  {onload => (
                    <div>
                      <img
                        src={image_smf}
                        onLoad={onload}
                        alt="Logo for the Sports Medicine Application"
                        className="drop-shadow"
                      />
                      <p className="text-xs sm:text-base leading-tight sm:leading-5 px-2 pt-1 pb-3 md:pt-3 text-gray-700 text-center">Sports Med Fitbit</p>
                    </div>
                  )}
                </FadeIn>
              </a>
              <a href="https://comresearchapp.nyit.edu/rca/login" className="w-1/3 md:w-1/4 lg:w-1/5 px-2 my-0 sm:my-1 sm:px-4">
                <FadeIn>
                  {onload => (
                    <div>
                      <img
                        src={image_rca}
                        onLoad={onload}
                        alt="Logo for The Regional Campus Application"
                        className="drop-shadow"
                      />
                      <p className="text-xs sm:text-base leading-tight sm:leading-5 px-2 pt-1 pb-3 md:pt-3 text-gray-700 text-center">Campus Application</p>
                    </div>
                  )}
                </FadeIn>
              </a>
              <a href="https://comapp.nyit.edu/attendance/login" className="w-1/3 md:w-1/4 lg:w-1/5 px-2 my-0 sm:my-1 sm:px-4">
                <FadeIn>
                  {onload => (
                    <div>
                      <img
                        src={attendance}
                        onLoad={onload}
                        alt="Logo for the Long Gene Database Application" className="drop-shadow"
                      />
                      <p className="text-xs sm:text-base leading-tight sm:leading-5 px-2 pt-1 pb-3 md:pt-3 text-gray-700 text-center">Attendance</p>
                    </div>
                  )}
                </FadeIn>
              </a>
              <a href="https://comapp.nyit.edu/clinic-jonesboro" className="w-1/3 md:w-1/4 lg:w-1/5 px-2 my-0 sm:my-1 sm:px-4">
                <FadeIn>
                  {onload => (
                    <div>
                      <img
                        src={image_clinic_jb}
                        onLoad={onload}
                        alt="Logo for the Jonesboro Clinic website"
                        className="drop-shadow"
                      />
                      <p className="text-xs sm:text-base leading-tight sm:leading-5 px-2 pt-1 pb-3 md:pt-3 text-gray-700 text-center">Clinic Jonesboro</p>
                    </div>
                  )}
                </FadeIn>
              </a>
              <a href="https://comapp.nyit.edu/nyitcom-help-is-here" className="w-1/3 md:w-1/4 lg:w-1/5 px-2 my-0 sm:my-1 sm:px-4">
                <FadeIn>
                  {onload => (
                    <div>
                      <img
                        src={image_help_is_here}
                        onLoad={onload}
                        alt="Logo for the NYITCOM Help is Here website"
                        className="drop-shadow"
                      />
                      <p className="text-xs sm:text-base leading-tight sm:leading-5 px-2 pt-1 pb-3 md:pt-3 text-gray-700 text-center">Help is Here</p>
                    </div>
                  )}
                </FadeIn>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default Home;