import React from 'react';
import Home from './components/Home';
class App extends React.Component {

  render() {
    return (
      <Home />
    )
  }
}

export default App;